<template>
  <FilterOverview :title="'KPI Historie'" :isInitiallyLoading="false">
    <template #toolbar-left>
      <b-button-group size="sm" style="width: 300px">
        <b-button
          v-for="kpiSite in kpiSites"
          :disabled="isBusy"
          :key="kpiSite"
          :id="kpiSite"
          :variant="kpiSite === 'Historie' ? 'primary' : ''"
          class="mr-1"
          style="width: 33%"
          :to="'/reisetermine/fvc/kpi-' + kpiSite.toLowerCase()"
        >
          {{ kpiSite }}
        </b-button>
      </b-button-group>
    </template>
    <template #filter><KPIFilter :isHistoryPage="true" /></template>

    <template #table="{ tableHeight, heightComponentInlineStyle }">
      <b-overlay :show="isBusy" opacity="1">
        <div class="d-flex mb-5" style="gap: 10px">
          <StackedLineChart
            style="width: 33%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoricGesamtverteilungStatusData"
            title="Verteilung Status"
            :fillArray="['#ebcc87', '#c16068', '#DAA17B', '#a2bf8a']"
            :stacked="false"
          />
          <StackedLineChart
            style="max-width: 33%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoricBearbeitungsData"
            title="Bearbeitete Daten"
            :fillArray="['#a2bf8a', '#c16068']"
            :stacked="true"
          />
          <StackedLineChart
            style="max-width: 33%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoricVerfuegbarkeitsData"
            title="Verteilung Bearbeitungsstatus"
            :fillArray="['#a2bf8a', '#c16068', '#1D4E89', '#D3D5D7']"
            :stacked="true"
          />
        </div>
        <div class="d-flex mb-5" style="gap: 10px">
          <StackedColumnChart
            style="width: 50%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoryNichtVerfuegbarAkzeptanzgrund"
            title="'Nicht verfügbar' Akzeptanzgründe"
            :stacked="true"
            :labelOnlyPercent="true"
          />
          <StackedColumnChart
            style="width: 50%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoryAufAnfrageAkzeptanzgrund"
            title="'Auf Anfrage' Akzeptanzgründe"
            :stacked="true"
            :labelOnlyPercent="true"
          />
        </div>
        <div class="d-flex mb-5" style="gap: 10px">
          <StackedColumnChart
            style="width: 50%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoryAufpreisGt200Akzeptanzgrund"
            title="'Aufpreis >200' Akzeptanzgründe"
            :stacked="true"
            :labelOnlyPercent="true"
          />
          <StackedColumnChart
            style="width: 50%; height: 400px; flex-grow: 1"
            :data="isBusy ? [] : getHistoricUrsachenData"
            title="Verfügbarkeit geschaffen: Ursachen"
            :stacked="true"
            :labelOnlyPercent="true"
          ></StackedColumnChart>
        </div>
      </b-overlay>
    </template>
  </FilterOverview>
</template>
<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import { mapGetters, mapState } from 'vuex';
import StackedLineChart from '@/components/flugverfuegbarkeit/stacked-line-chart.vue';
import StackedColumnChart from '@/components/flugverfuegbarkeit/stacked-column-chart.vue';
import { GET_HISTORIC_DATA } from '@/core/flugverfuegbarkeit/stores/kpi-fvc.module';
import KPIFilter from '@/components/flugverfuegbarkeit/kpi-filter.vue';
import VerfuegbarkeitGeschaffenChart from '@/components/flugverfuegbarkeit/verfuegbarkeit-geschaffen-chart.vue';

export default {
  components: {
    FilterOverview,
    StackedLineChart,
    StackedColumnChart,
    VerfuegbarkeitGeschaffenChart,
    KPIFilter,
  },
  data() {
    return {
      kpiSites: ['Dashboard', 'Leading', 'Historie'],
    };
  },
  computed: {
    ...mapGetters([
      'getHistoricGesamtverteilungStatusData',
      'getHistoryNichtVerfuegbarAkzeptanzgrund',
      'getHistoryAufAnfrageAkzeptanzgrund',
      'getHistoryAufpreisGt200Akzeptanzgrund',
      'getHistoricBearbeitungsData',
      'getHistoricVerfuegbarkeitsData',
      'getHistoricUrsachenData',
    ]),
    ...mapState({
      data: state => state.kpiFvc.data.historicData,
      isBusy: state => state.kpiFvc.isBusy,
    }),
  },
  created() {
    if (this.data.length === 0) this.$store.dispatch(GET_HISTORIC_DATA);
  },
};
</script>
<style></style>
